import * as React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { navigate } from 'gatsby';

import './../styles/main.scss';
import './404.scss';
import CookieConsent from '../layout/cookie-consent';

export default function NotFoundPage() {
  const browser = typeof window !== 'undefined' && window;

  return (
    <>
      {browser && (
        <Container className="error-body d-flex">
          <CookieConsent/>
          <div className="error d-flex">
            <Row className="align-items-center justify-content-between">
              <Col className="col-12 col-md-6">
                <img
                  className="img-fluid"
                  src="https://akzo-nobel-dulux-cdn-dev.s3.eu-west-2.amazonaws.com/misc/paint-bucket-2.svg"
                  alt="Spilled Paint Bucket"
                />
              </Col>
              <Col className="col-md-6 col-lg-5 mb-5 mb-md-4">
                <h1 className="error-title">404</h1>
                <h2 className="error-lead">Page not found.</h2>
                <p>
                  The page you were looking for has moved or doesn't exist.
                  Please try going back or returning to the homepage.
                </p>
                <div className="error-action">
                  <button
                    onClick={() => navigate(-1)}
                    className="btn btn-outline-primary mr-4"
                  >
                    Go Back
                  </button>
                  <a href="/" tabIndex="0" className="btn btn-primary">
                    Return to Home
                  </a>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      )}
    </>
  );
}
